import logo from './logo.svg';
import {useState} from 'react';


function App() {
  const [amount, setAmount] = useState(0)

  return (
    <>
    <div>
<div className="navbar-wrapper">
  <div className="navbar navbar-inverse navbar-fixed-top">
    <div className="navbar-inner">
      <div className="container">
        {/* Responsive navbar */}
        <a className="btn btn-navbar" data-toggle="collapse" data-target=".nav-collapse"><span className="icon-bar" /><span className="icon-bar" /><span className="icon-bar" />
        </a>
        <h1 className="brand"><a href="/">MKYC</a></h1>
        {/* navigation */}
        <nav className="pull-right nav-collapse collapse">
          <ul id="menu-main" className="nav">
            <li><a title="team" href="#about">About</a></li>
            <li><a title="works" href="#works">Works</a></li>
            <li><a title="contact" href="#contact">Contact</a></li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</div>
{/* Header area */}
<div id="header-wrapper" className="header-slider">
  <header className="clearfix">
    <div className="logo">
      <img src="img/other-logo1.png" alt="" />
    </div>

  </header>
</div>

{/* section: team */}
<section id="about" className="section">
  <div className="container">
    <h4>Who We Are</h4>
    <div className="row">

      <div className="span12">
        <p>Mount Kigali Youth center is Rwandese youth sewing and creating social enterprise. we empower local youth to overcome drug use, by giving them the opportunity to build and use their creative talents through sewing and providing rehabilitation support.</p>
      </div>

      <div className="span6">
        <div>


          <h5><strong>Our Mission</strong></h5>
          <p>
            Is to help young people stop using drugs and instead refocus their time & energy into sewing to improve their lives and their living.
          </p>

          <h5><strong>Our Vision</strong></h5>
          <p>
            is to expand the number of Youth Centers across the whole fo Rwanda, as creative hubs of talented youth overcomming drug use, by providing them the opportunity to develop a hopeful future through sewing and other artistic enterprises.
          </p>
        </div>
      </div>
      <div className="span6">



        <h5><strong>Our dedicated Rehabilitation support</strong></h5>
        <p>
          consists of a 6 month programme offered to all our staff, through mentoring from previous users and advice from medical specialists & therapists to help young people stop using drugs.
        </p>

        <h5><strong>Our Accomplishment so far</strong></h5>
        <p>
          <ul>
          	<li>
          		We have helped > 20 young people stop using drugs, supporting them and their families to change their everyday lives.
          	</li>
          	<li>
          		in 2021, we sold 370 items over the course of January to August, adding up to 8,4K USD in total revenues (vs 370 items for 9,8USD in 2020 FY)
          	</li>
          	
          	<li>
          		We have sold these products through hotel retail stores and tourist events, reaching hundreds of customers
          	</li>
          </ul>
        </p>














      </div>
    </div>


  </div>
  {/* /.container */}
</section>
{/* end section: team */}
{/* section: services */}



{/* end section: services */}
{/* section: works */}
<section id="works" className="section">
  <div className="container clearfix">
    <h4>Our Work</h4>
    {/* portfolio filter */}
    <div className="row">
      <div id="filters" className="span12">
        <ul className="clearfix">

        </ul>
      </div>
      {/* END PORTFOLIO FILTERING */}
    </div>
    <div className="row">
      <div className="span12">
        <div id="portfolio-wrap">

	 {/* portfolio item */}
        <div className="portfolio-item grid design web">
          <div className="">
            <a href="img/works/team.jpeg" data-pretty="prettyPhoto[gallery1]" className="portfolio-image">
              <img src="img/works/team.jpeg" alt=""/>
              <div className="portfolio-overlay">
                <div className="thumb-info">
                  <h5>Portfolio name</h5>
                  <i className="icon-plus icon-2x" />
                </div>
              </div>
            </a>
          </div>
        </div>
	
        {/* portfolio item */}
        <div className="portfolio-item grid design web">
          <div className="">
            <a href="img/works/bavet1.jpeg" data-pretty="prettyPhoto[gallery1]" className="portfolio-image">
              <img src="img/works/bavet1.jpeg" alt=""/>
              <div className="portfolio-overlay">
                <div className="thumb-info">
                  <h5>Portfolio name</h5>
                  <i className="icon-plus icon-2x" />
                </div>
              </div>
            </a>
          </div>
        </div>

        {/* portfolio item */}
        <div className="portfolio-item grid design web">
          <div className="">
            <a href="img/works/ikiringiti.jpeg" data-pretty="prettyPhoto[gallery1]" className="portfolio-image">
              <img src="img/works/ikiringiti.jpeg" alt=""/>
              <div className="portfolio-overlay">
                <div className="thumb-info">
                  <h5>Portfolio name</h5>
                  <i className="icon-plus icon-2x" />
                </div>
              </div>
            </a>
          </div>
        </div>

        {/* portfolio item */}
        <div className="portfolio-item grid design web">
          <div className="">
            <a href="img/works/inyamanswa.jpeg" data-pretty="prettyPhoto[gallery1]" className="portfolio-image">
              <img src="img/works/inyamanswa.jpeg" alt=""/>
              <div className="portfolio-overlay">
                <div className="thumb-info">
                  <h5>Portfolio name</h5>
                  <i className="icon-plus icon-2x" />
                </div>
              </div>
            </a>
          </div>
        </div>


        {/* portfolio item */}
        <div className="portfolio-item grid design web">
          <div className="">
            <a href="img/works/inzovu.jpeg" data-pretty="prettyPhoto[gallery1]" className="portfolio-image">
              <img src="img/works/inzovu.jpeg" alt=""/>
              <div className="portfolio-overlay">
                <div className="thumb-info">
                  <h5>Portfolio name</h5>
                  <i className="icon-plus icon-2x" />
                </div>
              </div>
            </a>
          </div>
        </div>

        {/* portfolio item */}
        <div className="portfolio-item grid design web">
          <div className="">
            <a href="img/works/kudoda.jpeg" data-pretty="prettyPhoto[gallery1]" className="portfolio-image">
              <img src="img/works/kudoda.jpeg" alt=""/>
              <div className="portfolio-overlay">
                <div className="thumb-info">
                  <h5>Portfolio name</h5>
                  <i className="icon-plus icon-2x" />
                </div>
              </div>
            </a>
          </div>
        </div>



        {/* portfolio item */}
        <div className="portfolio-item grid design web">
          <div className="">
            <a href="img/works/img1/utuzovu.jpg" data-pretty="prettyPhoto[gallery1]" className="portfolio-image">
              <img src="img/works/img1/utuzovu.jpg" alt=""/>
              <div className="portfolio-overlay">
                <div className="thumb-info">
                  <h5>Portfolio name</h5>
                  <i className="icon-plus icon-2x" />
                </div>
              </div>
            </a>
          </div>
        </div>

        {/* portfolio item */}
        <div className="portfolio-item grid design web">
          <div className="">
            <a href="img/works/mkycbag.jpg" data-pretty="prettyPhoto[gallery1]" className="portfolio-image">
              <img src="img/works/mkycbag.jpg" alt=""/>
              <div className="portfolio-overlay">
                <div className="thumb-info">
                  <h5>Portfolio name</h5>
                  <i className="icon-plus icon-2x" />
                </div>
              </div>
            </a>
          </div>
        </div>

        {/* portfolio item */}
        <div className="portfolio-item grid design web">
          <div className="">
            <a href="img/works/img/ikiringiti.jpg" data-pretty="prettyPhoto[gallery1]" className="portfolio-image">
              <img src="img/works/img/ikiringiti.jpg" alt=""/>
              <div className="portfolio-overlay">
                <div className="thumb-info">
                  <h5>Portfolio name</h5>
                  <i className="icon-plus icon-2x" />
                </div>
              </div>
            </a>
          </div>
        </div>

        {/* portfolio item */}
        <div className="portfolio-item grid design web">
          <div className="">
            <a href="img/works/mkyc_bags.jpg" data-pretty="prettyPhoto[gallery1]" className="portfolio-image">
              <img src="img/works/mkyc_bags.jpg" alt=""/>
              <div className="portfolio-overlay">
                <div className="thumb-info">
                  <h5>Portfolio name</h5>
                  <i className="icon-plus icon-2x" />
                </div>
              </div>
            </a>
          </div>
        </div>


        {/* portfolio item */}
        <div className="portfolio-item grid design web">
          <div className="">
            <a href="img/works/mkycbb.jpg" data-pretty="prettyPhoto[gallery1]" className="portfolio-image">
              <img src="img/works/mkycbb.jpg" alt=""/>
              <div className="portfolio-overlay">
                <div className="thumb-info">
                  <h5>Portfolio name</h5>
                  <i className="icon-plus icon-2x" />
                </div>
              </div>
            </a>
          </div>
        </div>

        {/* portfolio item */}
        <div className="portfolio-item grid design web">
          <div className="">
            <a href="img/works/img/bbags1.jpeg" data-pretty="prettyPhoto[gallery1]" className="portfolio-image">
              <img src="img/works/img/bbags1.jpeg" alt=""/>
              <div className="portfolio-overlay">
                <div className="thumb-info">
                  <h5>Portfolio name</h5>
                  <i className="icon-plus icon-2x" />
                </div>
              </div>
            </a>
          </div>
        </div>

        {/* portfolio item */}
        <div className="portfolio-item grid design web">
          <div className="">
            <a href="img/works/img/bbags2.jpeg" data-pretty="prettyPhoto[gallery1]" className="portfolio-image">
              <img src="img/works/img/bbags2.jpeg" alt=""/>
              <div className="portfolio-overlay">
                <div className="thumb-info">
                  <h5>Portfolio name</h5>
                  <i className="icon-plus icon-2x" />
                </div>
              </div>
            </a>
          </div>
        </div>


        {/* portfolio item */}
        <div className="portfolio-item grid design web">
          <div className="">
            <a href="img/works/img/bbags3.jpeg" data-pretty="prettyPhoto[gallery1]" className="portfolio-image">
              <img src="img/works/img/bbags3.jpeg" alt=""/>
              <div className="portfolio-overlay">
                <div className="thumb-info">
                  <h5>Portfolio name</h5>
                  <i className="icon-plus icon-2x" />
                </div>
              </div>
            </a>
          </div>
        </div>

        {/* portfolio item */}
        <div className="portfolio-item grid design web">
          <div className="">
            <a href="img/works/img/bbags4.jpeg" data-pretty="prettyPhoto[gallery1]" className="portfolio-image">
              <img src="img/works/img/bbags4.jpeg" alt=""/>
              <div className="portfolio-overlay">
                <div className="thumb-info">
                  <h5>Portfolio name</h5>
                  <i className="icon-plus icon-2x" />
                </div>
              </div>
            </a>
          </div>
        </div>

        {/* portfolio item */}
        <div className="portfolio-item grid design web">
          <div className="">
            <a href="img/works/img/bbags5.jpeg" data-pretty="prettyPhoto[gallery1]" className="portfolio-image">
              <img src="img/works/img/bbags5.jpeg" alt=""/>
              <div className="portfolio-overlay">
                <div className="thumb-info">
                  <h5>Portfolio name</h5>
                  <i className="icon-plus icon-2x" />
                </div>
              </div>
            </a>
          </div>
        </div>
        
        
        {/* portfolio item */}
        <div className="portfolio-item grid design web">
          <div className="">
            <a href="img/works/img1/comping.jpg" data-pretty="prettyPhoto[gallery1]" className="portfolio-image">
              <img src="img/works/img1/comping.jpg" alt=""/>
              <div className="portfolio-overlay">
                <div className="thumb-info">
                  <h5>Portfolio name</h5>
                  <i className="icon-plus icon-2x" />
                </div>
              </div>
            </a>
          </div>
        </div>
        
        {/* portfolio item */}
        <div className="portfolio-item grid design web">
          <div className="">
            <a href="img/works/img1/computer.jpg" data-pretty="prettyPhoto[gallery1]" className="portfolio-image">
              <img src="img/works/img1/computer.jpg" alt=""/>
              <div className="portfolio-overlay">
                <div className="thumb-info">
                  <h5>Portfolio name</h5>
                  <i className="icon-plus icon-2x" />
                </div>
              </div>
            </a>
          </div>
        </div>
        
        {/* portfolio item */}
        <div className="portfolio-item grid design web">
          <div className="">
            <a href="img/works/img1/ibikapubinini.jpg" data-pretty="prettyPhoto[gallery1]" className="portfolio-image">
              <img src="img/works/img1/ibikapubinini.jpg" alt=""/>
              <div className="portfolio-overlay">
                <div className="thumb-info">
                  <h5>Portfolio name</h5>
                  <i className="icon-plus icon-2x" />
                </div>
              </div>
            </a>
          </div>
        </div>
        
        {/* portfolio item */}
        <div className="portfolio-item grid design web">
          <div className="">
            <a href="img/works/img1/ibitenge.jpg" data-pretty="prettyPhoto[gallery1]" className="portfolio-image">
              <img src="img/works/img1/ibitenge.jpg" alt=""/>
              <div className="portfolio-overlay">
                <div className="thumb-info">
                  <h5>Portfolio name</h5>
                  <i className="icon-plus icon-2x" />
                </div>
              </div>
            </a>
          </div>
        </div>
        
        {/* portfolio item */}
        <div className="portfolio-item grid design web">
          <div className="">
            <a href="img/works/img1/igihanga.jpg" data-pretty="prettyPhoto[gallery1]" className="portfolio-image">
              <img src="img/works/img1/igihanga.jpg" alt=""/>
              <div className="portfolio-overlay">
                <div className="thumb-info">
                  <h5>Portfolio name</h5>
                  <i className="icon-plus icon-2x" />
                </div>
              </div>
            </a>
          </div>
        </div>
        
        {/* portfolio item */}
        <div className="portfolio-item grid design web">
          <div className="">
            <a href="img/works/img1/inzovu.jpg" data-pretty="prettyPhoto[gallery1]" className="portfolio-image">
              <img src="img/works/img1/inzovu.jpg" alt=""/>
              <div className="portfolio-overlay">
                <div className="thumb-info">
                  <h5>Portfolio name</h5>
                  <i className="icon-plus icon-2x" />
                </div>
              </div>
            </a>
          </div>
        </div>
        
        {/* portfolio item */}
        <div className="portfolio-item grid design web">
          <div className="">
            <a href="img/works/img1/marete.jpg" data-pretty="prettyPhoto[gallery1]" className="portfolio-image">
              <img src="img/works/img1/marete.jpg" alt=""/>
              <div className="portfolio-overlay">
                <div className="thumb-info">
                  <h5>Portfolio name</h5>
                  <i className="icon-plus icon-2x" />
                </div>
              </div>
            </a>
          </div>
        </div>
        
        {/* portfolio item */}
        <div className="portfolio-item grid design web">
          <div className="">
            <a href="img/works/img1/udukapu.jpg" data-pretty="prettyPhoto[gallery1]" className="portfolio-image">
              <img src="img/works/img1/udukapu.jpg" alt=""/>
              <div className="portfolio-overlay">
                <div className="thumb-info">
                  <h5>Portfolio name</h5>
                  <i className="icon-plus icon-2x" />
                </div>
              </div>
            </a>
          </div>
        </div>
        
        {/* portfolio item */}
        <div className="portfolio-item grid design web">
          <div className="">
            <a href="img/works/img1/utuzovu.jpg" data-pretty="prettyPhoto[gallery1]" className="portfolio-image">
              <img src="img/works/img1/utuzovu.jpg" alt=""/>
              <div className="portfolio-overlay">
                <div className="thumb-info">
                  <h5>Portfolio name</h5>
                  <i className="icon-plus icon-2x" />
                </div>
              </div>
            </a>
          </div>
        </div>


         {/* portfolio item */}
         <div className="portfolio-item grid design web">
          <div className="">
            <a href="img/works/lastmg/img1.jpeg" data-pretty="prettyPhoto[gallery1]" className="portfolio-image">
              <img src="img/works/lastmg/img1.jpeg" alt=""/>
              <div className="portfolio-overlay">
                <div className="thumb-info">
                  <h5>Portfolio name</h5>
                  <i className="icon-plus icon-2x" />
                </div>
              </div>
            </a>
          </div>
        </div>

        {/* portfolio item */}
        <div className="portfolio-item grid design web">
          <div className="">
            <a href="img/works/lastmg/img2.jpeg" data-pretty="prettyPhoto[gallery1]" className="portfolio-image">
              <img src="img/works/lastmg/img2.jpeg" alt=""/>
              <div className="portfolio-overlay">
                <div className="thumb-info">
                  <h5>Portfolio name</h5>
                  <i className="icon-plus icon-2x" />
                </div>
              </div>
            </a>
          </div>
        </div>

        {/* portfolio item */}
        <div className="portfolio-item grid design web">
          <div className="">
            <a href="img/works/lastmg/img3.jpeg" data-pretty="prettyPhoto[gallery1]" className="portfolio-image">
              <img src="img/works/lastmg/img3.jpeg" alt=""/>
              <div className="portfolio-overlay">
                <div className="thumb-info">
                  <h5>Portfolio name</h5>
                  <i className="icon-plus icon-2x" />
                </div>
              </div>
            </a>
          </div>
        </div>

        {/* portfolio item */}
        <div className="portfolio-item grid design web">
          <div className="">
            <a href="img/works/lastmg/img4.jpeg" data-pretty="prettyPhoto[gallery1]" className="portfolio-image">
              <img src="img/works/lastmg/img4.jpeg" alt=""/>
              <div className="portfolio-overlay">
                <div className="thumb-info">
                  <h5>Portfolio name</h5>
                  <i className="icon-plus icon-2x" />
                </div>
              </div>
            </a>
          </div>
        </div>

        {/* portfolio item */}
        <div className="portfolio-item grid design web">
          <div className="">
            <a href="img/works/lastmg/img5.jpeg" data-pretty="prettyPhoto[gallery1]" className="portfolio-image">
              <img src="img/works/lastmg/img5.jpeg" alt=""/>
              <div className="portfolio-overlay">
                <div className="thumb-info">
                  <h5>Portfolio name</h5>
                  <i className="icon-plus icon-2x" />
                </div>
              </div>
            </a>
          </div>
        </div>

      {/* portfolio item */}
      <div className="portfolio-item grid design web">
          <div className="">
            <a href="img/works/lastmg/img6.jpeg" data-pretty="prettyPhoto[gallery1]" className="portfolio-image">
              <img src="img/works/lastmg/img6.jpeg" alt=""/>
              <div className="portfolio-overlay">
                <div className="thumb-info">
                  <h5>Portfolio name</h5>
                  <i className="icon-plus icon-2x" />
                </div>
              </div>
            </a>
          </div>
        </div>

        {/* portfolio item */}
        <div className="portfolio-item grid design web">
          <div className="">
            <a href="img/works/lastmg/img7.jpeg" data-pretty="prettyPhoto[gallery1]" className="portfolio-image">
              <img src="img/works/lastmg/img7.jpeg" alt=""/>
              <div className="portfolio-overlay">
                <div className="thumb-info">
                  <h5>Portfolio name</h5>
                  <i className="icon-plus icon-2x" />
                </div>
              </div>
            </a>
          </div>
        </div>

        {/* portfolio item */}
        <div className="portfolio-item grid design web">
          <div className="">
            <a href="img/works/lastmg/img8.jpeg" data-pretty="prettyPhoto[gallery1]" className="portfolio-image">
              <img src="img/works/lastmg/img8.jpeg" alt=""/>
              <div className="portfolio-overlay">
                <div className="thumb-info">
                  <h5>Portfolio name</h5>
                  <i className="icon-plus icon-2x" />
                </div>
              </div>
            </a>
          </div>
        </div>

        {/* portfolio item */}
        <div className="portfolio-item grid design web">
          <div className="">
            <a href="img/works/lastmg/img10.jpeg" data-pretty="prettyPhoto[gallery1]" className="portfolio-image">
              <img src="img/works/lastmg/img10.jpeg" alt=""/>
              <div className="portfolio-overlay">
                <div className="thumb-info">
                  <h5>Portfolio name</h5>
                  <i className="icon-plus icon-2x" />
                </div>
              </div>
            </a>
          </div>
        </div>

	{/* portfolio item */}
        <div className="portfolio-item grid design web">
          <div className="">
            <a href="img/works/img1/utwokumeza.jpg" data-pretty="prettyPhoto[gallery1]" className="portfolio-image">
              <img src="img/works/img1/utwokumeza.jpg" alt=""/>
              <div className="portfolio-overlay">
                <div className="thumb-info">
                  <h5>Portfolio name</h5>
                  <i className="icon-plus icon-2x" />
                </div>
              </div>
            </a>
          </div>
        </div>



          {/* end portfolio item */}
        </div>
      </div>
    </div>
  </div>
</section>
{/* spacer section */}


<iframe width="100%" height="480" src="https://www.youtube-nocookie.com/embed/JFTpQy6OhaE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

<iframe width="100%" height="480" src="https://www.youtube.com/embed/_5SHrF6rr9c?start=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

{/* spacer section */}
<section className="spacer green">
  <div className="container">
    <div className="row">
      <div className="span6 alignright flyLeft">
        <blockquote className="large">
          We have helped more than 20 people to recover from drug use this far. Your donation will ensure we help even more.
        </blockquote>
      </div>
      <div className="span6 aligncenter flyRight">
      <br/>
      <br/>
      <br/>
      <br/>


      <form action="https://www.paypal.com/donate" method="post" target="_top">
  <input type="hidden" name="hosted_button_id" value="PC6AKE38SJDTQ" />
  <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
  <img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
  </form>


      </div>
    </div>
  </div>
</section>
{/* end spacer section */}

<section id="contact" className="section green">
  <div className="container">
    <h4>If you are interested in our products, please get in touch</h4>

	<a href="#"><i className="icon-circled icon-bgdark icon-phone icon-2x" />+250 787 025 362 WhatsApp and Call</a>

    <div className="blankdivider30">
    
    <br/>
    </div>
    <div className="row">
      <div className="span12">
        <div className="cform" id="contact-form">
          <div id="sendmessage">Your message has been sent. Thank you!</div>
          <div id="errormessage" />
          <form action method="post" role="form" className="contactForm">
            <div className="row">
              <div className="span6">
                <div className="field your-name form-group">
                  <input type="text" name="name" className="form-control" id="name" placeholder="Your Name" data-rule="minlen:4" data-msg="Please enter at least 4 chars" />
                  <div className="validation" />
                </div>
                <div className="field your-email form-group">
                  <input type="text" className="form-control" name="email" id="email" placeholder="Your Email" data-rule="email" data-msg="Please enter a valid email" />
                  <div className="validation" />
                </div>
                <div className="field subject form-group">
                  <input type="text" className="form-control" name="subject" id="subject" placeholder="Subject" data-rule="minlen:4" data-msg="Please enter at least 8 chars of subject" />
                  <div className="validation" />
                </div>
              </div>
              <div className="span6">
                <div className="field message form-group">
                  <textarea className="form-control" name="message" rows={5} data-rule="required" data-msg="Please write something for us" placeholder="Message" defaultValue={""} />
                  <div className="validation" />
                </div>
                <input type="submit" defaultValue="Send message" className="btn btn-theme pull-left" />
              </div>
            </div>
          </form>
        </div>
      </div>
      {/* ./span12 */}
    </div>
  </div>
</section>
<footer>
  <div className="container">
    <div className="row">
      <div className="span6 offset3">
        <ul className="social-networks">
          <li><a href="#"><i className="icon-circled icon-bgdark icon-instagram icon-2x" /></a></li>
          <li><a href="#"><i className="icon-circled icon-bgdark icon-twitter icon-2x" /></a></li>
          <li><a href="#"><i className="icon-circled icon-bgdark icon-dribbble icon-2x" /></a></li>
          <li><a href="#"><i className="icon-circled icon-bgdark icon-pinterest icon-2x" /></a></li>
        </ul>
        <p className="copyright">
          © Mount kigali youth center. All rights reserved.
        </p><div className="credits">
          {/*
        All the links in the footer should remain intact.
        You can delete the links only if you purchased the pro version.
        Licensing information: https://bootstrapmade.com/license/
        Purchase the pro version with working PHP/AJAX contact form: https://bootstrapmade.com/buy/?theme=Maxim
      */}

        </div>
        <p />
      </div>
    </div>
  </div>
  {/* ./container */}
</footer>
</div>



    </>
  );
}

export default App;
